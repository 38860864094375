import Validator from "@/utils/validator"
import LANGUAGES from "@/data/languages.json"
import COUNTRIES from "@/data/countries_flags.json"
import API from "@/utils/api"

import type { OrientationFieldProps } from "./Field"

export const FORM_PAGES: ({
  name: string
  icon?: string
  description?: string
} & OrientationFieldProps)[][] = [
  [
    {
      label: "Name*",
      name: "name",
      disabled: true,
      // required: true,
      // validators: [Validator.required],
    },
    {
      label: "Email*",
      name: "email",
      type: "email",
      disabled: true,
      // required: true,
      // validators: [Validator.required],
    },
    {
      label: "Your Story",
      name: "about",
      type: "textarea",
    },
    {
      label: "Mother tongue*",
      name: "mother_tongue",
      type: "select",
      autoComplete: "language",
      options: [
        { _: "" },
        ...LANGUAGES.sort((a, b) => a.localeCompare(b)).map((l) => ({
          [l]: l,
        })),
      ],
      required: true,
      validators: [Validator.required],
    },
    {
      label: "City*",
      name: "city",
      required: true,
      autoComplete: "address-level2",
      validators: [Validator.required],
    },
    {
      label: "Country*",
      name: "country",
      type: "select",
      autoComplete: "country-name",
      options: [
        { _: "" },
        ...Object.entries(COUNTRIES).map(([c, flag]) => ({
          [c]: [c, flag].join(" "),
        })),
      ],
      required: true,
      validators: [Validator.required],
    },
  ],
  [
    { label: "Profile photo", type: "file", name: "avatar" },
    {
      label: "Screen name*",
      required: true,
      name: "username",
      autoComplete: "username",
      minLength: 3,
      maxLength: 15,
      validators: [
        ({ value }) =>
          value &&
          API.post("/rest-auth/user/check/", { username: value }).then(
            ({ response }) => response.ok
          ),
        () => true,
        Validator.required,
        Validator.minLength,
        Validator.maxLength,
      ],
      validationText: [
        "Screen name not available",
        "Checking availability",
        "Screen name is required",
        "Screen name must be at least 3 characters",
        "Screen name must be at most 15 characters",
      ],
    },
    {
      label: "Password*",
      required: true,
      name: "password",
      type: "password",
      autoComplete: "new-password",
      minLength: 8,
      validators: [
        ({ value }) => /[A-Z]/.test(value) && /[a-z]/.test(value),
        Validator.minLength,
        ({ value }) => /[0-9~`!@#$%^&*()_+{}|:"<>?]/.test(value),
        Validator.required,
      ],
      validationText: [
        "Upper and lowercase letters",
        "At least 8 characters",
        "Contains a number or symbol",
      ],
    },
  ],
  [
    {
      label: "Past Being",
      theme: "relational",
      description: "Description of a past being you want to channel",
      name: "past_being",
      autoComplete: "off",
      icon: "/images/illustrations/energy-guy.png",
    },
    {
      label: "Future Being",
      theme: "relational",
      description: "Description of a future being you want to channel",
      name: "future_being",
      autoComplete: "off",
      icon: "/images/illustrations/clock.png",
    },
    {
      label: "An Alive Being",
      theme: "relational",
      description: "Name a person who is currently alive you want to channel",
      name: "alive_being",
      autoComplete: "off",
      icon: "/images/illustrations/heart.png",
    },
    {
      label: "Custodial Species",
      theme: "relational",
      description: "Select from",
      name: "custodial_species",
      autoComplete: "off",
      type: "select",
      icon: "/images/illustrations/staircase-head.png",
      options: [{}],
    },
    {
      label: "Professor",
      theme: "relational",
      description: "What would your professor be?",
      name: "professor",
      autoComplete: "off",
      icon: "/images/illustrations/professor-portrait.png",
    },
  ],
  [
    {
      label: "Mindset Areas",
      name: "knowledge_field",
      helperText: "Select one from the 5 mindset areas",
      type: "radio",
      options: [
        // { imagination: "Imagination" },
        // { "mentor mindset": "Mentor Mindset" },
        // { "unlikely connectinos": "Unlikely Connections" },
        // { "systems designers": "Systems Designers" },
        // { "relational economics": "Relational Economics" },
      ],
      required: true,
      validators: [Validator.required],
    },
    {
      label: "Mentor Values",
      name: "values",
      helperText:
        "Select the first 3 Mentor Mindset River Runs quests you will complete first.",
      type: "checkbox",
      options: [
        // { hope: "Hope" },
        // { change: "Change" },
        // { freedom: "Freedom" },
        // { rebellious: "Rebellious" },
        // { listening: "Listening" },
        // { empathy: "Empathy" },
        // { brave_goals: "Brave goals" },
        // { no_shame: "No shame" },
        // { initiative: "Initiative" },
        // { yes_and: "Yes and" },
        // { forgiveness: "Forgiveness" },
        // { kindness: "Kindness" },
        // { gift_of_time: "Gift of time" },
        // { failure: "Failure" },
        // { asking_questions: "Asking questions" },
        // { hard_work_discipline: "Hard work + discipline" },
        // { know_yourself: "Know yourself" },
        // { mentors_not_saviours: "Mentors not saviours" },
      ],
      validators: [
        Validator.required,
        Validator.minLength,
        Validator.maxLength,
      ],
      min: 3,
      max: 3,
    },
  ],
]
