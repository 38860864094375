"use client"

import API from "@/utils/api"
import {
  createContext,
  useState,
  useMemo,
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  Dispatch,
  SetStateAction,
  FC,
  ReactNode,
} from "react"
import { usePathname, useRouter } from "next/navigation"
import { signIn } from "next-auth/react"
import { captureException } from "@sentry/nextjs"

import useToast from "@/hooks/useToast"
import ErrorWithData from "@/utils/ErrorWithData"
import type { User } from "@/types/User"

import { FORM_PAGES } from "./FormDefinition"
import { AsyncOrImmediateValidator, OrientationFieldProps } from "./Field"

export const OnboardingContext = createContext({
  form: {} as Record<string, any>,
  setForm: ((_form) => {}) as Dispatch<SetStateAction<Record<string, any>>>,
  onChange: ((_e) => {}) as ChangeEventHandler<HTMLInputElement>,
  onSubmit: ((_e) => {}) as FormEventHandler,
  pageErrors: [] as Record<string, number[]>[],
  isSubmitted: false,
  user: null as User | null,
})

export const OnboardingContextProvider: FC<{
  children: ReactNode
  initialData?: Record<string, any>
  formDefinition?: OrientationFieldProps[][]
}> = ({ children, initialData = {}, formDefinition = FORM_PAGES }) => {
  const router = useRouter()
  const pathname = usePathname()
  const { addToast } = useToast()
  // Only password is set here so that the validators are visible when it's empty
  const [form, setForm] = useState<Record<string, any>>({
    password: "",
    ...initialData,
  })
  const [isSubmitted, setSubmitted] = useState(false)
  const [user, setUser] = useState<User | null>(null)

  // Caches results of async validators
  const [asyncValidationValues, setAsyncValidationValues] = useState<
    Map<string, boolean | null>
  >(new Map())

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const { value, type, files, name } = e.currentTarget
    if (type === "checkbox") {
      setForm((f) => {
        const lastValue: string[] = f[name] || []
        return {
          ...f,
          [name]: lastValue.includes(value)
            ? lastValue.filter((v) => v !== value)
            : [...lastValue, value],
        }
      })
      return
    }
    setForm((f) => ({ ...f, [name]: files?.[0] || value }))
  }, [])

  // HOC to create a validator function for a specific field
  const createFieldValidator = useCallback(
    (field: { name: string } & OrientationFieldProps) =>
      (validator: AsyncOrImmediateValidator, validatorIndex: number) => {
        const validatorCacheKey = [
          field.name,
          form[field.name],
          validatorIndex,
        ].join(".")
        const resolvedValue = asyncValidationValues.get(validatorCacheKey)
        // Should return null for running async validators, true for passed and false for failed
        if (typeof resolvedValue !== "undefined") {
          return resolvedValue
        }
        const validatorPassed = validator({
          ...field,
          value: form[field.name],
        })
        if (validatorPassed instanceof Promise) {
          // Immediately put null into the cache map at this async key so the validator doesn't run again
          setAsyncValidationValues(
            (v) => new Map(v.set(validatorCacheKey, null))
          )

          // Run the async validator
          validatorPassed
            .then((passed) => {
              setAsyncValidationValues(
                (v) => new Map(v.set(validatorCacheKey, passed))
              )
            })
            .catch((_e) =>
              setAsyncValidationValues(
                (v) => new Map(v.set(validatorCacheKey, false))
              )
            )

          return null
        }
        return validatorPassed
      },
    [asyncValidationValues, form]
  )

  const pageErrors = useMemo(
    () =>
      formDefinition.map((fields) =>
        fields.reduce<Record<string, number[]>>((acc, field) => {
          if (!field.validators) {
            return acc
          }
          const validatorPasses = field.validators.map(
            createFieldValidator(field)
          )
          const validatorFailedIndexes = validatorPasses.reduce<number[]>(
            (passes, passed, idx) => {
              if (passed) {
                return passes
              }
              // Indeterminate / async data gives its index + 1 for different validation text
              if (passed === null) {
                return [...passes, idx + 1]
              }
              return [...passes, idx]
            },
            []
          )
          if (validatorFailedIndexes?.length < 1) {
            return acc
          }
          return { ...acc, [field.name]: validatorFailedIndexes }
        }, {})
      ),
    [createFieldValidator, formDefinition]
  )

  const onSubmit = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault()
      if (pageErrors.every((page) => Object.values(page)?.length > 0)) {
        return
      }
      router.prefetch([pathname, "passport"].join("/"))
      // Wait to change to new screen so exit transition can run
      const t = setTimeout(() => {
        router.push([pathname, "passport"].join("/"))
      }, 500)
      try {
        const formData = new FormData()
        Object.entries(form).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => formData.append(key, v))
          } else {
            formData.append(key, value)
          }
        })
        const { data, response } = await API.post<{ key: string; user: User }>(
          "/rest-auth/registration/",
          formData
        )
        if (!response.ok) {
          throw new ErrorWithData("Error registering user", data)
        }
        await new Promise((resolve) => {
          setTimeout(resolve, 5000)
        })
        await signIn("token", {
          redirect: false,
          token: data.key,
        })
        setUser(data.user)
        setSubmitted(true)
      } catch (err: any) {
        clearTimeout(t)
        captureException(err)
        console.error(err)
        addToast(err, "error")
        router.push(pathname)
      }
    },
    [addToast, form, pageErrors, pathname, router]
  )

  const fields = useMemo(
    () => ({
      form,
      setForm,
      onChange,
      onSubmit,
      pageErrors,
      isSubmitted,
      user,
    }),
    [form, onChange, onSubmit, pageErrors, isSubmitted, user]
  )

  return (
    <OnboardingContext.Provider value={fields}>
      {children}
    </OnboardingContext.Provider>
  )
}
export default OnboardingContext
