export const API_URL = process.env.NEXT_PUBLIC_API_URL

type Message = Record<string, any>
type GenericHeaders = Record<string, string>

type ErrorMessage =
  | {
      error: string
      message: {
        messages: {
          message: string
        }[]
      }[]
    }
  | {
      error: string
      message: string
    }
  | {
      errors: {
        message: string
      }[]
    }

async function processResponse<T = Message>(r: Response) {
  const response = r
  let data: ErrorMessage | Message | string = {}

  try {
    data = await r.text()
    data = JSON.parse(data)
  } catch (e) {
    console.warn("Bad JSON response", data, e)
    // TODO: Throw
  }
  if (!response.ok) {
    const error = response?.statusText || "Unknown error"
    console.error(error, data, response.url)
  }
  return {
    data,
    response,
  } as {
    data: T
    response: Response
  }
}

export const API = {
  token: "",
  extraHeaders: {} as GenericHeaders,

  async getHeaders() {
    return {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: this.token ? `Token ${this.token}` : "",
      ...this.extraHeaders,
    } as GenericHeaders
  },

  async get<T = Message>(route: string) {
    const headers = await this.getHeaders()
    const url = route.startsWith("http") ? route : `${API_URL}${route}`

    return fetch(url, {
      headers,
    }).then<ReturnType<typeof processResponse<T>>>(processResponse)
  },

  async modify<T = Message>(
    method: string,
    route: string,
    data: { [propName: string]: any } | FormData = {},
    extraHeaders: GenericHeaders = {}
  ) {
    const headers = await this.getHeaders()

    if (data instanceof FormData) {
      delete headers["Content-Type"]
    }

    const url = route.startsWith("http") ? route : `${API_URL}${route}`
    return fetch(url, {
      method,
      headers: {
        ...headers,
        ...extraHeaders,
      },
      body: data instanceof FormData ? data : JSON.stringify(data),
    }).then<ReturnType<typeof processResponse<T>>>(processResponse)
  },

  async post<T = Message>(
    route: string,
    data: { [propName: string]: any } = {},
    extraHeaders: GenericHeaders = {}
  ) {
    return this.modify<T>("POST", route, data, extraHeaders)
  },

  async put<T = Message>(route: string, data: { [propName: string]: any }) {
    return this.modify<T>("PUT", route, data)
  },

  async patch<T = Message>(route: string, data: { [propName: string]: any }) {
    return this.modify<T>("PATCH", route, data)
  },

  async delete<T = Message>(route: string) {
    return this.modify<T>("DELETE", route)
  },

  setHeaders(newHeaders: GenericHeaders) {
    this.extraHeaders = newHeaders
    return this
  },

  setToken(token: string) {
    this.token = token
    return this
  },

  unsetToken() {
    this.token = ""
    return this
  },
}

export default API
