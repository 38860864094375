const FileTypes = {
  pdf: ["application/pdf"],
  image: ["image/jpeg", "image/png", "image/gif", "image/webp"],
  video: ["video/mp4", "video/mpeg"],
  audio: ["audio/mpeg", "audio/wav", "audio/x-wav"],
}

export const Validator = {
  required({ value = "" }: { value: string | string[] }) {
    return Array.isArray(value) ? value.length > 0 : !!value
  },
  minLength({
    value,
    min,
    minLength,
  }: {
    value: string | string[]
    min?: number
    minLength?: number
  }) {
    const length = minLength || min || 0
    return !!(value && value.length >= length)
  },
  maxLength({
    value,
    max,
    maxLength,
  }: {
    value: string | string[]
    max?: number
    maxLength?: number
  }) {
    const length = maxLength || max || Infinity
    return !!(value && value.length <= length)
  },
  validFileType({
    value,
    type,
  }: {
    value: FileList
    type: keyof typeof FileTypes
  }) {
    const files = Array.from(value)

    // Loop over files and ensure all files are of valid type
    return Object.values(files).every(
      (file) => FileTypes[type].indexOf(file.type) > -1
    )
  },
  validFileSize({ value, max }: { value: FileList; max: number }) {
    // Expects input in MBs
    const bytes = max * 1024 * 1024
    const files = Array.from(value)

    return Object.values(files).every((file) => file.size < bytes)
  },
}

export default Validator
